import { CSSProperties, ElementType, ReactNode } from 'react';

import Typography, { Variant } from '@components/atoms/Typography';

import classes from './Header.module.scss';

const sizeMap: Record<number, Variant> = {
  1: 'h1',
  2: 'h2',
  3: 'h3',
  4: 'h4',
  5: 'body1',
  6: 'body2',
};

type Props = {
  id?: string;
  size: number;
  children: ReactNode;
  align?: CSSProperties['textAlign'];
  renderAs?: ElementType;
};

const Header = ({ id, children, size, align, renderAs }: Props): JSX.Element => (
  <Typography
    anchor-id={id}
    className={classes.header}
    variant={sizeMap[size]}
    align={align}
    display="block"
    renderAs={renderAs}
    data-header={`h${size}`}
  >
    {children}
  </Typography>
);

export default Header;
